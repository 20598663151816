// ----------------------------------------------
// Pages Module
// ----------------------------------------------

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

// ng Recaptcha
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

// Pages
import { HomeComponent } from './home/home.component';
import { DesignComponent } from './design/design.component';
import { DevelopmentComponent } from './development/development.component';
import { MarketingComponent } from './marketing/marketing.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { SharedModule } from '../shared/shared.module';

// Import ParticlesModule
import { ParticlesModule } from 'angular-particle';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ParticlesModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  declarations: [
    HomeComponent,
    DesignComponent,
    DevelopmentComponent,
    MarketingComponent,
    AboutComponent,
    ContactComponent,
    NotfoundComponent
  ],
  exports: [
    HomeComponent,
    DesignComponent,
    DevelopmentComponent,
    MarketingComponent,
    AboutComponent,
    ContactComponent,
    NotfoundComponent
  ]
})
export class PagesModule { }
