import { Component, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lkd-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {

  // --------------------------------------------
  // Logos Carousel
  // --------------------------------------------
  // Configuracion
  configCarousel = {
    slidesPerView: 10,
    spaceBetween: 10,
    slidesPerGroup: 1,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 8,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 6,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      }
    }
  };
  // Imagenes
  imgCarousel: string[] = [
    '/assets/img/logosDev/wp.png',
    '/assets/img/logosDev/md.jpg',
    '/assets/img/logosDev/bs.png',
    '/assets/img/logosDev/sass.png',
    '/assets/img/logosDev/css.png',
    '/assets/img/logosDev/html.png',
    '/assets/img/logosDev/py.png',
    '/assets/img/logosDev/gsap.png',
    '/assets/img/logosDev/angular.png',
    '/assets/img/logosDev/ionic.png',
    '/assets/img/logosDev/ac.png',
    '/assets/img/logosDev/nodejs.png',
    '/assets/img/logosDev/mongo.png',
    '/assets/img/logosDev/firebase.png',
    '/assets/img/logosDev/gc.png',
    '/assets/img/logosDev/pm.png',
    '/assets/img/logosDev/ads.png',
    '/assets/img/logosDev/an.png',
    '/assets/img/logosDev/bing.png'
  ];
  // --------------------------------------------
  // FIN Logos Carousel
  // --------------------------------------------
  // --------------------------------------------
  // Text Carousel
  // --------------------------------------------
  configCarouselTxt = {
    slidesPerView: 4,
    spaceBetween: 0,
    slidesPerGroup: 1,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      }
    }
  };
  // Imagenes
  frasesCarousel: string[] = [
    'web development full stack',
    'web creative design',
    'worldpress development',
    'e-commerce',
    'graphic design',
    'online marketing',
    'social marketing',
    'search engine optimization',
    'server side optimization',
    'google ads',
    'bing advertising'
  ];
  // --------------------------------------------
  // FIN Text Carousel
  // --------------------------------------------

  // --------------------------------------------
  // Particles JS
  // --------------------------------------------
  width = 100;
  height = 100;
  style: object = {
    'z-index': -1,
    'position': 'fixed',
    'width': '100%',
    'height': '100%',
    'top': 0,
    'left': 0,
    'right': 0,
    'bottom': 0,
    'background-color': '#171717'
  };
  params: object = {
    particles: {
      number: {
        value: 100,
      },
      color: {
        value: '#FFC200'
      },
      shape: {
        type: 'circle',
      },
    }
  };
  // --------------------------------------------
  // FIN Particles JS
  // --------------------------------------------
  // --------------------------------------------
  // Formulario
  // --------------------------------------------
  formAbierto = false;

  abrirForm() {
    if ( !this.formAbierto ) {
      return this.formAbierto = true;
    } else {
      return this.formAbierto = false;
    }
  }
  cerrarForm() {
    if ( this.formAbierto ) {
      return this.formAbierto = false;
    }
  }
  // --------------------------------------------
  // Fin Formulario
  // --------------------------------------------

  constructor() { }

  ngOnInit() {
  }
}
