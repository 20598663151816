import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'lkd-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() configCarousel: any;
  @Input() imgCarousel: string[];

  constructor() { }

  ngOnInit() { }

}
