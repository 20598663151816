import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Interface de contacto
import { IContact } from '../models/contact.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendContactService {

  private contact: IContact;

  constructor( private http: HttpClient ) {

    this.contact = {
      name: '',
      phone: '',
      email: '',
      country: '',
      message: '',
      captcha: ''
    };
  }

  sendContact( nContact: IContact ): Observable<any> {
    return this.http.post('assets/php/sendContact.php', nContact );
  }
}
