import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lkd-carousel-txt',
  templateUrl: './carousel-txt.component.html',
  styleUrls: ['./carousel-txt.component.scss']
})
export class CarouselTxtComponent implements OnInit {

  @Input() configCarouselTxt: any;
  @Input() frasesCarousel: string[];

  constructor() { }

  ngOnInit() {
  }

}
