import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lkd-design',
  templateUrl: './design.component.html',
  styles: []
})
export class DesignComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
