import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lkd-card-img',
  templateUrl: './card-img.component.html',
  styles: []
})
export class CardImgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
