// ----------------------------------------------
// Shared Components Module
// ----------------------------------------------

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Shared Components
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CardIcoComponent } from './card-ico/card-ico.component';
import { CardImgComponent } from './card-img/card-img.component';
import { FormComponent } from './form/form.component';
import { CarouselComponent } from './carousel/carousel.component';

// Swiper Component
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CarouselTxtComponent } from './carousel-txt/carousel-txt.component';

// Material
import { MaterialModule } from './material.module';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
// End Swiper Component

@NgModule({
  imports: [
    CommonModule,
    SwiperModule,
    MaterialModule
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    CardIcoComponent,
    CardImgComponent,
    FormComponent,
    CarouselComponent,
    CarouselTxtComponent
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    CardIcoComponent,
    CardImgComponent,
    FormComponent,
    CarouselComponent,
    CarouselTxtComponent,
    SwiperModule,
    MaterialModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class SharedModule { }
