import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of } from 'rxjs';
// Interface: Contact
import { IContact } from '../../models/contact.model';
// Servicio de envio de mail
import { SendContactService } from '../../services/send-contact.service';


@Component({
  selector: 'lkd-contact',
  templateUrl: './contact.component.html',
  styles: []
})
export class ContactComponent implements OnInit {

  submited = false;

  nContact: IContact = {
    name: '',
    phone: '',
    email: '',
    country: '',
    message: '',
    captcha: ''
  };

  constructor( private sendNewContact: SendContactService ) { }

  ngOnInit() {
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  onSubmit() {
    this.submited = true;

    this.sendNewContact.sendContact( this.nContact ).subscribe(
      res => {
        console.log('Enviado', res );
      }, error => {
        console.error( 'Error en el envio', error );
      }
    );
  }

}
